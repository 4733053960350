import React, { useState } from 'react'
import { Form } from 'antd'
import { selectHandleInputProduct } from '../../../../store/search/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setProductsListAction } from '../../../../store/cart/cart.reducer'
import { HandleInputProductType } from '../../../../store/search/reducer'
import { HandleInputForm } from '../side-handle-editor/handle-input-form/index'
import { useCartStorage } from '../cart/cart.storage'
import { CartItem } from '../../../../store/cart/types'

export function ManualCart() {
  const handleInputProduct = useSelector(selectHandleInputProduct)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [price_currency] = useState<string>('USD')

  const { getSavedCart, saveCart } = useCartStorage()

  const onFinish = (rawProduct: HandleInputProductType) => {
    const newProduct = {
      ...rawProduct,
      price_currency,
    } as unknown as CartItem
    const cartItems = getSavedCart()
    const newCartItems = cartItems.concat([newProduct])

    saveCart(newCartItems)
    dispatch(setProductsListAction(newCartItems))

    form.resetFields()
  }

  return (
    <HandleInputForm
      initialValues={handleInputProduct}
      onFinishHandler={onFinish}
      showFinishBtn
    />
  )
}
